import React from 'react';
import PropTypes from 'prop-types';
import DynamicComponent from './DynamicComponent';

import T from 'i18n-react';
import GetText from "./components/GetText";
import {empty} from "./components/textutil";
import AppContext from "./AppContext"
import PageLink from "./components/PageLink";

import imgKuLogo from 'url:/public/img/ku-logo.png';
import imgNsfLogo from 'url:/public/img/nsf.png';
import imgOsepIdeaLogo from 'url:/public/img/osep-idea.png';
import imgTexthelpLogo from 'url:/public/img/texthelp.png';
import imgCastFooterLogo from 'url:/public/img/cast/logo-footer.svg';

export default class Footer extends DynamicComponent {
    static contextType = AppContext;

    showFooter(){
        const docType= this.context.fileType;
        if(undefined !== docType){
            if("comparison" === docType || "question-exp" === docType || "cause-effect" === docType || "cera" === docType)
                return false;
        }
        return true;
    }

    render() {
        const docType= this.context.fileType;
        const type = (!empty(docType) && this.props.valid)
            ? docType
            : "utility";
        const footer1 = <GetText type={type} item={'footer'} />;
        const year = new Date().getFullYear();

        return(
            this.showFooter()
            ?
            <footer className="print-none">
                <div className="footer-site">
                    <div className="container">
                        <div className="footer-site-brand">
                            <div className="footer-site-logo">
                                <div className="footer-site-title">Corgi</div>{' '}
                                <div className="footer-site-tagline">Co-Organize Your Learning</div>
                            </div>
                        </div>
                        <div className="footer-site-row row">
                            <div className="col">
                                <div className="footer-site-header">Site Map</div>
                                <ul className="list-unstyled">
                                    <li className="list-item"><PageLink page="docs" hash="#new">Begin a new guide</PageLink></li>
                                    <li className="list-item"><PageLink page="docs">My recent guides</PageLink></li>
                                    <li className="list-item"><PageLink page="about">About Corgi</PageLink></li>
                                    <li className="list-item"><PageLink page="support">How to use Corgi</PageLink></li>
                                    <li className="list-item"><PageLink page="faq">Corgi FAQ</PageLink></li>
                                    <li className="list-item"><PageLink page="attributions">Attributions</PageLink></li>
                                    <li className="list-item"><PageLink page={"privacy"}>Privacy Policy</PageLink></li>
                                    <li className="list-item"><PageLink page={"accessibility"}>Accessibility Policy</PageLink></li>
                                </ul>
                            </div>
                            <div className="col">
                                <div className="footer-site-header">Our Partners</div>
                                <ul className="list-unstyled">
                                    <li className="list-item"><a href="https://kucrl.ku.edu/">University of Kansas</a></li>
                                    <li className="list-item"><a href="https://www.sri.com/education-learning">SRI International</a></li>
                                    <li className="list-item"><a href="https://www.sccoe.org/">Santa Clara County Office of Education</a></li>
                                    <li className="list-item"><a href="https://www.evergreenevaluation.net">Evergreen Evaluation &amp; Consulting, Inc.</a></li>
                                </ul>
                            </div>
                            <div className="col">
                                <div className="footer-site-header">Contact Us</div>
                                <ul className="list-unstyled">
                                    <li className="media">
                                        <div className="media-object">
                                            <span className="fa fa-envelope" aria-hidden="true"></span>
                                        </div>
                                        <div className="media-body">
                                            <a href="mailto:corgi@cast.org">corgi@cast.org</a>
                                        </div>
                                    </li>
                                    <li className="media">
                                        <div className="media-object">
                                            <span className="fa fa-phone" aria-hidden="true"></span>
                                        </div>
                                        <div className="media-body">
                                            (781) 245-2212
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-site-media media">
                            <div className="media-object">
                                <a href="https://kucrl.ku.edu/"><img src={imgKuLogo} className="img-fluid" alt="KU: The University of Kansas"/></a>
                            </div>
                            <div className="media-body">
                                <p>{footer1}</p>
                            </div>
                        </div>
                        <div className="footer-site-media media">
                            <div className="media-object">
                                <a href={T.translate("footer.nsf.url")}><img src={imgNsfLogo}
                                                                   className="img-fluid"
                                                                   alt="National Science Foundation" /></a>
                            </div>
                            <div className="media-body">
                                <T.p text={{key: "footer.nsf.grant"}}/>
                            </div>
                        </div>
                        <div className="footer-site-media media">
                            <div className="media-object">
                                <a href="https://osepideasthatwork.org/"><img src={imgOsepIdeaLogo}
                                                                   className="img-fluid"
                                                                   alt="OSEP Ideas That Work" /></a>
                            </div>
                            <div className="media-body">
                                <T.p text={{key: "footer.osep.grant"}}/>
                            </div>
                        </div>
                        <div className= "footer-site-media media">
                            <div className="media-object">
                                <a href="https://texthelp.com"><img src={imgTexthelpLogo}
                                                                    className="img-fluid" alt="Texthelp"/></a>
                            </div>
                            <div className="media-body">
                                <T.p text={{key: "footer.texthelp.thanks"}}></T.p>
                            </div>
                        </div>
                        <div className="footer-site-copy row" style={{paddingTop:'0.75rem', paddingBottom: '.75rem', borderTop: '1px solid #bfc6cd'}}>
                            <div className="col">
                                {/*
                                <ul className="list list-horizontal">
                                    <li className="list-item"><PageLink page={"privacy"}>CORGI'S Privacy Policy</PageLink></li>
                                </ul>
                                */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-cast">
                    <div className="container">
                        <div className="footer-cast-brand">
                            <a href="http://www.cast.org" className="footer-cast-logo">
                                <img src={imgCastFooterLogo} alt=""/>
                                    <div className="footer-cast-title">CAST</div>
                                    <div className="footer-cast-tagline">Until learning has no limits</div>
                            </a>
                        </div>
                        <div className="footer-cast-row row">
                            <div className="col">
                                <div className="footer-cast-header">Connect with us</div>
                                Follow us:
                                <ul className="list list-horizontal">
                                    <li className="list-item"><a href="https://www.facebook.com/CASTUDL" className="footer-cast-social"><span
                                        className="fab fa-facebook-square" aria-hidden="true"></span>
                                        <span className="sr-only">Follow us on Facebook"></span></a></li>
                                    <li className="list-item"><a href="https://www.twitter.com/CAST_UDL" className="footer-cast-social"><span
                                        className="fab fa-twitter-square" aria-hidden="true"></span>
                                        <span className="sr-only">Follow us on Twitter"></span></a></li>
                                    <li className="list-item"><a href="https://www.linkedin.com/company/castorg" className="footer-cast-social"><span
                                        className="fab fa-linkedin" aria-hidden="true"></span>
                                        <span className="sr-only">Follow us on LinkedIn"></span></a></li>
                                    <li className="list-item"><a href="https://www.instagram.com/cast_udl" className="footer-cast-social"><span
                                        className="fab fa-instagram-square" aria-hidden="true"></span>
                                        <span className="sr-only">Follow us on Instragram"></span></a></li>
                                    <li className="list-item"><a href="https://www.pinterest.com/cast_udl" className="footer-cast-social"><span
                                        className="fab fa-pinterest-square" aria-hidden="true"></span>
                                        <span className="sr-only">Follow us on Pinterest"></span></a></li>
                                    <li className="list-item"><a href="https://www.youtube.com/user/UDLCAST" className="footer-cast-social"><span
                                        className="fab fa-youtube-square" aria-hidden="true"></span>
                                        <span className="sr-only">Follow us on YouTube"></span></a></li>
                                </ul>
                                <ul className="list-unstyled">
                                    <li className="list-item"><a href="https://bit.ly/cast-newsletter">Subscribe to our newsletter</a></li>
                                    <li className="list-item"><a href="https://us4.campaign-archive.com/home/?u=89e11c7455f4cb757154eb608&id=7396dac1a2">Newsletter archive</a></li>
                                </ul>
                            </div>
                            <div className="col">
                                <div className="footer-cast-header">More from CAST</div>
                                <ul className="list-unstyled">
                                    <li className="list-item"><a href="https://publishing.cast.org">CAST Publishing</a></li>
                                    <li className="list-item"><a href="https://udlguidelines.cast.org">UDL Guidelines</a></li>
                                    <li className="list-item"><a href="http://udloncampus.cast.org">UDL on Campus</a></li>
                                    <li className="list-item"><a href="https://aem.cast.org/">National Center on Accessible Educational Materials (AEM Center)</a></li>
                                    <li className="list-item"><a href="https://cites.cast.org">Center on Inclusive Technology &amp; Education Systems (CITES)</a></li>
                                </ul>
                            </div>
                            <div className="col">
                                <div className="footer-cast-header">Contact CAST</div>
                                <ul className="list-unstyled">
                                    <li className="media">
                                        <div className="media-object">
                                            <span className="fa fa-envelope" aria-hidden="true"></span>
                                        </div>
                                        <div className="media-body">
                                            <a href="mailto:cast@cast.org">cast@cast.org</a>
                                        </div>
                                    </li>
                                    <li className="media">
                                        <div className="media-object">
                                            <span className="fa fa-phone" aria-hidden="true"></span>
                                        </div>
                                        <div className="media-body">
                                            (781) 245-2212
                                        </div>
                                    </li>
                                    <li className="media">
                                        <div className="media-object">
                                            <span className="fa fa-location-dot" aria-hidden="true"></span>
                                        </div>
                                        <div className="media-body">
                                            50 Salem St<br/>
                                            Building B, Ste 303<br/>
                                            Lynnfield, MA 01940-0030
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-cast-copy row">
                            <div className="col">
                                <ul className="list list-horizontal">
                                    <li className="list-item"><a href="https://www.cast.org/get-involved/donate" className="footer-cast-btn-donate btn">Donate</a></li>
                                    {/* <li className="list-item"><a href="https://www.cast.org/site/accessibility">Accessibility Policy</a></li> */}
                                    <li className="list-item"><a href="https://www.cast.org/site/terms-of-use">Terms of Use</a></li>
                                </ul>
                            </div>
                            <div className="col">
                                &copy; CAST, Inc. {year}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            : null
        );
    }

}

Footer.propTypes = {
    doc : PropTypes.object,
    valid: PropTypes.bool.isRequired
};
